.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333; /* Mismo color que el fondo de la aplicación */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 1s ease-out;
}

.loading-screen.hidden {
  opacity: 0;
  pointer-events: none;
}

.loader {
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #2ea1d1 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #2ea1d1);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
