.contact_ {
  width: 100%;
  background-color: #333333;
  padding: 2rem 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  flex-wrap: wrap;
  gap: 1rem;
}

.left_ {
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.contact-heading h1 {
  font-weight: 700;
  color: #222;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.contact-heading h1 span {
  color: #007bff;
}

.contact-heading .text {
  color: #555;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.contact-heading .text a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-heading .text a:hover {
  color: #0056b3;
}

.form-wrapper {
  width: 100%;
}

.contact-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.input-wrap {
  position: relative;
}

.w-100 {
  grid-column: span 2;
}

.contact-input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s;
}

.contact-input:focus {
  border-color: #007bff;
}

.contact-input::placeholder {
  opacity: 0;
}

.input-wrap label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1rem;
  color: #aaa;
  transition: all 0.3s;
}

.icon_ {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: #aaa;
}

.textarea .contact-input {
  resize: none;
  min-height: 150px;
}

.input-wrap.focus label,
.input-wrap.not-empty label {
  top: -1.5rem;
  left: 1rem;
  font-size: 0.75rem;
  color: #007bff;
}

.contact-buttons {
  display: flex;
  justify-content: space-between;
  grid-column: span 2;
  margin-top: 1rem;
}

.contact-buttons a {
  text-decoration: none;
}

.btn_ {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1rem;
}

.upload {
  background-color: #f1f1f1;
  color: #555;
  display: flex;
  align-items: center;
}

.upload:hover {
  background-color: #e9e9e9;
}

.upload input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.btn_[type="submit"] {
  background-color: #007bff;
  color: #fff;
}

.btn_[type="submit"]:hover {
  background-color: #0056b3;
}

.right_ {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  width: 50%;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.image-wrapper .img {
  width: 100%;
  height: auto;
}

.wave-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 100%;
  background-color: green;
}

.wave-wrap svg {
  position: absolute;
  height: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% - 2px);
}

#wave {
  fill: blue;
  height: 100%;
}

.dashed-wave {
  position: absolute;
  z-index: 3;
  height: 130%;
  bottom: 60%;
  left: -28px;
  transform: translateY(50%);
}

#dashed-wave {
  fill: none;
  stroke: #007bff;
  stroke-width: 1px;
  stroke-dasharray: 6.5;
  opacity: 0.8;
}

.btn_.whatsapp {
  background-color: #25d366;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.btn_.whatsapp:hover {
  background-color: #1ebe57;
}

@media screen and (max-width: 768px) {
  .contact-buttons {
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (max-width: 1631px) {
  .right_ {
    display: none;
  }
}
