.footer {
  background: #333;
  color: #f1f1f1;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  padding: 10px 20px;
}

.logo-text {
  font-size: 2em;
  font-weight: bold;
  color: #2ea1d1;
}

.footer-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.footer-section p,
.footer-section a,
.footer-section span {
  color: #f1f1f1;
  text-decoration: none;
}

.footer-section a:hover {
  color: #2ea1d1;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section .contact-item {
  margin-bottom: 10px;
}

.footer-section .socials a {
  margin-right: 10px;
  color: #f1f1f1;
  font-size: 1.5em;
}

.footer-section .socials a:hover {
  color: #2ea1d1;
}

.footer-bottom {
  background: #222;
  color: #f1f1f1;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Media Queries */

/* Tablet Devices (min-width: 768px and max-width: 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 80%;
    margin-bottom: 30px;
    text-align: center;
  }

  .footer-section ul {
    padding: 0;
    text-align: center;
  }

  .footer-section ul li {
    display: inline-block;
    margin: 0 10px;
  }

  .footer-section .socials a {
    margin: 0 10px;
  }
}

/* Mobile Devices (max-width: 767px) */
@media (max-width: 767px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 90%;
    margin-bottom: 30px;
    text-align: center;
  }

  .footer-section ul {
    padding: 0;
    text-align: center;
  }

  .footer-section ul li {
    display: inline-block;
    margin: 0 10px;
  }

  .footer-section .socials a {
    margin: 0 10px;
  }
}

/* Small Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 95%;
    margin-bottom: 30px;
    text-align: center;
  }

  .footer-section ul {
    padding: 0;
    text-align: center;
  }

  .footer-section ul li {
    display: inline-block;
    margin: 0 10px;
  }

  .footer-section .socials a {
    margin: 0 10px;
  }
}

/* Extra Small Mobile Devices (max-width: 360px) */
@media (max-width: 360px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-section ul {
    padding: 0;
    text-align: center;
  }

  .footer-section ul li {
    display: inline-block;
    margin: 0 5px;
  }

  .footer-section .socials a {
    margin: 0 5px;
    font-size: 1.2em;
  }

  .footer-section p,
  .footer-section a,
  .footer-section span {
    font-size: 0.9em;
  }

  .footer-section h2 {
    font-size: 1.2em;
  }

  .logo-text {
    font-size: 1.5em;
  }
}
