.ocean {
  width: 100%;
  position: relative;
  background: #333333;
  margin-top: -5px;
}

.wave {
  background: url("../../img/wave.svg") repeat-x;
  position: absolute;
  top: -198px;
  width: 100%;
  height: 200px;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 485px) {
  .wave {
    top: -140px;
  }
}
