.animated-text {
  display: inline-block;
  font-weight: bold;
  color: white;
  font-size: 1.5em;
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
