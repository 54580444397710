body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.intro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #333;
  color: white;
  height: 100vh;
}

.intro {
  max-width: 40%;
  text-align: left;
  margin-bottom: 20px;
  position: relative;
}

.intro h1 {
  font-size: 3em;
  margin: 0;
  line-height: 1.4;
  color: #f1f1f1;
}

.intro h1 span {
  color: #2ea1d1;
  font-weight: 700;
  font-size: 1.7em;
}

.intro p {
  font-size: 1.2em;
  margin-top: 20px;
  color: #595959;
  line-height: 1.5;
}

.intro p b {
  color: #595959;
  transition: color 0.3s;
}

.intro p b:hover {
  color: #2ea1d1;
  transition: color 0.3s;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  align-items: flex-start;
}

.buttons-container a {
  text-decoration: none;
}

.buttons {
  display: flex;
  gap: 20px;
}

.whatsapp-button-container {
  margin-top: 20px;
}

.btn {
  background-color: #3ac5d9;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.contact {
  background-color: #555;
}

.btn:hover {
  background-color: #2ea1d1;
}

.btn.contact:hover {
  background-color: #666;
}

.btn_.whatsapp {
  background-color: #25d366;
  color: white;
}

.btn_.whatsapp:hover {
  background-color: #1ebd55;
}

.download-icon {
  margin-right: 10px;
  color: white;
}

.scroll-down {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 80px;
  left: 48%;
  transform: translateX(-50%);
  flex-direction: row;
  z-index: 1;
}

.scroll-down p {
  margin: 0 20px;
  transform: translateY(-20px);
  cursor: pointer;
  transition: color 0.3s;
}

.scroll-down p:hover {
  color: #2ea1d1;
}

.scroll-icon {
  font-size: 50px;
  animation: rebounce 3s infinite;
  color: white;
}

.scroll-icon.left {
  margin-right: 10px;
}

.scroll-icon.right {
  margin-left: 10px;
}

.profile-pic {
  position: relative;
  margin-left: 50px;
  max-width: 400px;
  height: auto;
  text-align: center;
}

.profile-pic img {
  max-width: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.profile-pic::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: 120%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  background: radial-gradient(
    circle,
    rgba(46, 161, 209, 0.6) 0%,
    transparent 80%
  );
  filter: blur(20px);
}

.intro-section .intro h1,
.intro-section .intro h2 {
  opacity: 0;
  animation: slideInFromLeft 1s forwards;
}

.intro-section .intro p,
.intro-section .buttons-container {
  opacity: 0;
  animation: slideInFromLeft 1s forwards;
}

.intro-section .intro h1 {
  animation-delay: 0.3s;
}

.intro-section .intro h2 {
  animation-delay: 0.6s;
}

.intro-section .intro p {
  animation-delay: 0.9s;
}

.intro-section .buttons-container {
  animation-delay: 1.2s;
}

.intro-section .profile-pic,
.intro-section .scroll-down {
  opacity: 0;
  animation: slideInFromLeft 1s forwards;
  animation-delay: 1.5s;
}

.last-visit {
  margin-top: 30px;
  font-size: 1em;
  color: #b0b0b0;
  text-align: left;
}

@keyframes rebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 480px) {
  .intro-section {
    flex-direction: column;
    padding: 20px;
    height: auto;
    text-align: center;
  }

  .intro {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .intro h1 {
    font-size: 2em;
  }

  .intro h1 span {
    font-size: 1.5em;
  }

  .intro p {
    font-size: 1em;
  }

  .buttons-container {
    align-items: center;
  }

  .buttons {
    flex-direction: column;
    gap: 10px;
  }

  .profile-pic {
    margin: 0 auto 20px;
  }

  .scroll-down {
    margin-top: 20px;
    bottom: -200px;
    z-index: 1;
  }

  .scroll-down p {
    font-size: 0.8em;
  }

  .scroll-icon {
    font-size: 30px;
  }
}

/* Tablet Devices (min-width: 481px and max-width: 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .intro-section {
    flex-direction: column;
    padding: 30px;
    height: auto;
    text-align: center;
  }

  .intro {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .intro h1 {
    font-size: 2.5em;
  }

  .intro h1 span {
    font-size: 1.7em;
  }

  .intro p {
    font-size: 1.1em;
  }

  .buttons-container {
    align-items: center;
  }

  .buttons {
    flex-direction: column;
    gap: 15px;
  }

  .profile-pic {
    margin: 0 auto 20px;
  }

  .scroll-down {
    margin-top: 20px;
    flex-direction: row;
    bottom: -245px;
    z-index: 1;
  }

  .scroll-down p {
    font-size: 0.9em;
  }

  .scroll-icon {
    font-size: 40px;
  }
}

@media (min-width: 481px) and (max-width: 515px) {
  .scroll-down {
    bottom: -300px;
  }
}

/* Laptop Devices (min-width: 769px and max-width: 1250px) */
@media (min-width: 769px) and (max-width: 1250px) {
  .intro-section {
    flex-direction: column;
    padding: 40px;
    height: auto;
    justify-content: flex-start;
  }

  .intro {
    max-width: 70%;
    text-align: center;
    position: static;
    margin: 0 auto;
  }

  .intro h1 {
    font-size: 2.5em;
  }

  .intro h1 span {
    font-size: 1.7em;
  }

  .intro p {
    font-size: 1.1em;
  }

  .buttons-container {
    align-items: center;
  }

  .buttons {
    flex-direction: row;
    gap: 15px;
  }

  .profile-pic {
    max-width: 300px;
    margin: 20px auto 0;
    text-align: center;
  }

  .profile-pic img {
    max-width: 100%;
  }

  .scroll-down {
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: -180px;
    left: 48%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .scroll-down p {
    margin: 10px 0;
  }

  .scroll-icon {
    font-size: 50px;
  }
}

@media (min-width: 1251px) and (max-width: 1600px) {
  .scroll-down {
    bottom: -100px;
  }
}
