.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #333;
  color: white;
  position: relative;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.8);
}

.header__nav {
  position: absolute;
  flex-grow: 1;
  left: 18%;
}

.header__nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 0;
  padding: 0;
}

.header__nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.header__nav ul li {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

.header__nav a.active {
  background-color: #2ea1d1;
  color: white;
  border-color: #2ea1d1;
}

.header__nav a:hover {
  background-color: #2ea1d1;
  color: white;
  border-color: #2ea1d1;
}

.header__resume {
  position: absolute;
  right: 18%;
}

.header__resume .resume-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #2ea1d1;
  border-radius: 5px;
  overflow: hidden;
  transition: color 0.5s ease;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 1.5s ease-in forwards;
}

.header__resume .resume-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #3ac5d9;
  transition: left 0.5s ease;
  z-index: -1;
}

.header__resume .resume-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #2ea1d1, #3ac5d9);
  transition: left 0.5s ease;
  z-index: -1;
}

.header__resume .resume-button:hover::before {
  left: 0;
}

.header__resume .resume-button:hover::after {
  left: 0;
}

.header__resume .resume-button:hover {
  background-position: 100%;
  color: white;
}

.header__resume .resume-button span {
  position: relative;
  z-index: 1;
}

.menu-toggle {
  display: none;
  position: absolute;
  right: 20px;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 1.5s ease-in forwards;
}

.menu-toggle .fa {
  color: white;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Media Queries */
@media (max-width: 855px) {
  .header {
    padding: 20px 10px;
    animation: none;
  }

  .header__nav {
    display: none;
  }

  .header__nav.open {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #474747;
    border-radius: 5px;
    text-align: center;
    padding: 20px 0;
    z-index: 1;
    animation: slideDown 0.5s ease forwards;
  }

  .header__nav.close {
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #333;
    text-align: center;
    padding: 20px 0;
    z-index: 1;
    border: 1px solid #2ea1d1;
    animation: slideUp 0.5s ease forwards;
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .header__nav ul {
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }

  .header__nav ul li a {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #2ea1d1;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }

  .header__nav ul li a:hover {
    background-color: #2ea1d1;
    color: white;
  }

  .header__resume {
    position: static;
    margin-top: 10px;
  }

  .menu-toggle {
    display: block;
  }
}

/* Media Queries for Laptop */
@media (min-width: 856px) and (max-width: 1465px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 30px 15px;
  }

  .header__nav {
    position: static;
    order: 1;
    margin-bottom: 10px;
  }

  .header__nav ul {
    gap: 40px;
  }

  .header__resume {
    position: static;
    order: 2;
    margin-top: 10px;
  }

  .header__resume .resume-button {
    padding: 8px 16px;
  }
}

@media (max-width: 995px) {
  .header {
    flex-direction: column;
    padding: 20px;
  }

  .header__nav {
    order: 2;
    margin-top: 10px;
  }

  .header__nav ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  .header__resume {
    order: 1;
    margin-bottom: 10px;
  }

  .header__resume .resume-button {
    padding: 8px 16px;
  }
}
